.input {
  font-weight: 300;

  padding: var(--space-m);

  color: var(--white);
  border: none;
  background-color: #303030;

  &::placeholder {
    color: var(--color-gray);
  }
}

.input__type_invalid {
  border: 1px solid var(--color-error);
}
